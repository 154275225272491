import { createApp } from 'vue'
import App from './App.vue'
import {router} from "@/router";
import "./assets/css/argon-dashboard-blanc.css";
import "./assets/css/nucleo-icons.css"
import "./assets/css/nucleo-svg.css";


import {store} from "@/store";
import { guards } from './guards';
import VueAxios from 'vue-axios';
import axios from 'axios';
import { alert } from './utils/alerts';
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import { apiUrl } from './sharevariables';
import 'bootstrap'
 /* eslint-disable */
import "./assets/js/core/popper.min.js"
import "./assets/js/core/bootstrap.min.js"
import "./assets/js/plugins/perfect-scrollbar.min.js"
import "./assets/js/plugins/smooth-scrollbar.min.js"
import "./assets/js/plugins/dragula/dragula.min.js"
import "./assets/js/plugins/jkanban/jkanban.js"
import "./assets/js/plugins/chartjs.min.js"
import "./assets/img/home-image.jpg"
import { formatter } from './utils/formaterUtil';
import Vue3FormWizard from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import Popper from "vue3-popper"
import "./theme.css"; 
import InLoginModal from './views/auth/InLogin';
import { handler } from "./utils/axios_error_handler";

const VueTelInputOptions = {
    mode: "international",
    // onlyCountries: ['RW','NG', 'GH', "GB", "US", "CA","KE","ZA","UG","TZ","CM","CI","LR","GM","SL","NG","GH","TG","BJ","BF","NE","ML","SN","MR","GN","GW","GQ","ST","CV","AO","CD","CF","CG","GA","TD","KM","SC","MU","RE","YT","ZW","ZM","MW","LS","BW","SZ","NA","ZA","SH","ER","DJ","SO","ET","KE","UG","BI","MZ","MG"]
  }

const app = createApp(App)

app.use(router);
app.use(store);
app.use(VueAxios, axios)
app.use(VueTelInput, VueTelInputOptions); 
app.use(Vue3FormWizard)
app.component("Popper", Popper);
app.component('InLoginModal', InLoginModal);
app.config.globalProperties.$guards = guards
app.config.globalProperties.$alert = alert
app.config.globalProperties.$apiUrl = apiUrl
app.config.globalProperties.$formatter=formatter
app.config.globalProperties.$axios_error_handler = handler


app.mount('#app')
