
const mutations = {
    
    mutateUserProfile:(state,payload)=>{
      state.auth.userProfile=payload
    },
    mutateCredentials: (state, payload) => {
        state.auth.credentials = payload
    },
    mutateIsAuthenticated: (state, payload) => {
        state.auth.isAuthenticated = payload
    },

    mutateUserPermissions: (state, payload) => {
        state.auth.userPermissions = payload
    },
    mutateLoading: (state, payload) => {
        state.loading = payload
    },
}
export {mutations}
