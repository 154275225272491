
import { customersManagementRoutes } from './customersManagementRoutes'
import { projectsManagementRoutes } from './projectsManagementRoutes'
import {inventoryManagementRoutes} from './inventoryManagementRoutes'
import {requestsManagementRoutes} from './requestsManagementRoutes'
import { guards } from '@/guards'
import { router } from '.'
const dashboardRoutes = [
    {
        path: '',
        name: 'Dashboard',
        component: ()=>import('../views/dashboard/dashboard'),
        beforeEnter: () => guards.is_authenticated() || router.push({ name: 'Unauthorized' }),
    },
    {
        path: '/account',
        name: 'Account',
        component: ()=>import('../views/dashboard/UserManagement/MyAccount'),
        beforeEnter: () => guards.is_authenticated() || router.push({ name: 'Unauthorized' }),
    },
    {
        path: '/users',
        name: 'Users',
        component: ()=>import('../views/dashboard/UserManagement/Users'),
        beforeEnter: () => guards.isAdministrator() || router.push({ name: 'Unauthorized' }),
    },
    {
        path:'/create-user',
        name: 'CreateUser',
        component:()=>import('../views/dashboard/UserManagement/CreateUser'),
        beforeEnter: () => guards.isAdministrator() || router.push({ name: 'Unauthorized' }),
    },
    {
        path:'/edit-user/:username',
        name: 'EditUser',
        component:()=>import('../views/dashboard/UserManagement/EditUser'),
        beforeEnter: () => guards.isAdministrator() || router.push({ name: 'Unauthorized' }),
        props: true
    },
    
    ...customersManagementRoutes,
    ...projectsManagementRoutes,
    ...inventoryManagementRoutes,
    ...requestsManagementRoutes,

    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: ()=>import('../views/auth/Unauthorized')
    },
    {
        path: '/dashboard/404/',
        name: '404_dashboard',
        component: () => import('@/views/error_pages/404Status')
    },
    {
        path: '/dashboard/403/',
        name: '403_dashboard',
        component: () => import('@/views/error_pages/403Status')
    },
    {
        path: '/dashboard/500/',
        name: '500_dashboard',
        component: () => import('@/views/error_pages/500Status')
    },
    

]
export { dashboardRoutes }
