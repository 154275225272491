<template>
    <div v-if="showModal" class="modal fade show" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-model="true" style="display: block;">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <section>
                        <div class="page-header">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 d-flex flex-column mx-lg-0 mx-auto">
                                        <div class="card card-plain">
                                            <div class="ml-5 text-start">
                                                <p class="mb-0">Enter Password to login again</p>
                                            </div>
                                            <div class="card-body">
                                                <form role="form">
                                                    <div class="mb-3">
                                                        <input type="password"
                                                            class="form-control form-control-alternative"
                                                            placeholder="Password" v-model="password"
                                                            aria-label="Password">
                                                    </div>
                                                    <div class="text-center">
                                                        <button type="button" style="background-color: gray;"
                                                            class="btn btn-sm w-100 text-white mt-2 mb-0"
                                                            @click="login()">Sign
                                                            in</button>
                                                    </div>
                                                </form>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
/* eslint-disable */
import eventBus from '@/utils/eventBus';

import { alert } from '@/utils/alerts';
export default {

    name: "login",
    data() {
        return {
            username: "",
            password: "",
            showModal :false,
            user:{}
        }
    },
    methods: {
        showModalHandler() {
          this.showModal = true;
        },
        login: function () {
            if(this.user?.username){
                this.showModal=true
                this.$store.dispatch("login", {
                    username: this.user.username,
                    password: this.password
               });
              
            }else{
                this.$router.push("login")
            }
            
        }
    },
    created() {
        this.user=this.$store.state?.auth?.userProfile
        eventBus.on('show-login-modal', this.showModalHandler);
    },
} 
</script>

<style scoped></style>