import { guards } from '@/guards'
import { router } from '.'

const requestsManagementRoutes = [
  
    {
        path:'/requests',
        name: 'Requests',
        component:()=>import('../views/dashboard/RequestManagement/Requests.vue'),
        beforeEnter: () => guards.isRequester() || guards.isStockOfficer() || guards.isRequestApprover()  || router.push({ name: 'Unauthorized' }),

          // REQUESTER, REQUEST_APPROVER, STOCK_OFFICER, STOCK_MANAGER
    },
    {
        path:'/create-requests',
        name: 'CreateRequest',
        component:()=>import('../views/dashboard/RequestManagement/CreateRequest.vue'),
        beforeEnter: () => guards.isRequester() || router.push({ name: 'Unauthorized' }),
        
        //  REQUESTER // hIDE bUTTON IN CASE OF STOCK OFFICER, STOCK MANAGER, REQUEST_APPROVER
    },

    {
        path:'/requests-details/:id',
        name: 'RequestDetails',
        component:()=>import('../views/dashboard/RequestManagement/RequestDetails.vue'),
        beforeEnter: () => guards.isRequester() || guards.isStockOfficer() || guards.isRequestApprover() || router.push({ name: 'Unauthorized' }),
        props:true
        // REQUESTER, REQUEST_APPROVER, STOCK_OFFICER(WHEN IS READ FOR FULLFILLMENT),

    }


  




  
    
]
export { requestsManagementRoutes }
