// import axios from 'axios'
// import qs from 'querystring'
const getters = {
    getToken: state => {
        return state.auth.credentials
    },
    getUserRoles:state =>{
        return state.auth.userProfile.roles.map(role => role.name);
    }
}
export { getters }
