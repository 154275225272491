import { store } from "@/store";
import { router } from "../router";

const Role_Enum = {
    ROLE_ADMIN: {'raw':'ROLE_ADMIN', friendly: 'Admin'},
    ROLE_STOCK_OFFICER: {'raw':'ROLE_STOCK_OFFICER', friendly: 'Stock Officer'},
    ROLE_STOCK_MANAGER: {'raw':'ROLE_STOCK_MANAGER', friendly: 'Stock Manager'},
    ROLE_REQUEST_APPROVER: {'raw':'ROLE_REQUEST_APPROVER', friendly: 'Request Approver'},
    ROLE_EXECUTIVE: {'raw':'ROLE_EXECUTIVE', friendly: 'Executive'},
    ROLE_USER: {'raw':'ROLE_USER', friendly: 'User'},
    ROLE_REQUESTER: {'raw':'ROLE_REQUESTER', friendly: 'Requester'},
    ROLE_TECHNICIAN: {'raw':'ROLE_TECHNICIAN', friendly: 'Requester'}
}

const guards={
   
    is_authenticated:()=>{
        if(store.state.auth.isAuthenticated )
            return true
        else
           return router.push({ name: 'Home' })
    },
    isStockOfficer:()=>{
        return store.state.auth?.userPermissions.includes(Role_Enum.ROLE_STOCK_OFFICER.raw)
    },
    isStockManager:()=>{
        return store.state.auth?.userPermissions.includes(Role_Enum.ROLE_STOCK_MANAGER.raw)
    },
    isExecutive:()=>{
        return store.state.auth?.userPermissions.includes(Role_Enum.ROLE_EXECUTIVE.raw)
    },
    isTechnician:()=>{
        return store.state.auth?.userPermissions.includes(Role_Enum.ROLE_TECHNICIAN.raw)
    },
    isRequester:()=>{
        return store.state.auth?.userPermissions.includes(Role_Enum.ROLE_REQUESTER.raw)
    },
    isRequestApprover:()=>{
        return store.state.auth?.userPermissions.includes(Role_Enum.ROLE_REQUEST_APPROVER.raw)
    },
    isUser:()=>{
        return store.state.auth?.userPermissions.includes(Role_Enum.ROLE_USER.raw)
    },

    isAdministrator:()=>{
        return store.state.auth?.userPermissions.includes(Role_Enum.ROLE_ADMIN.raw)
    },
}

export {guards}