
import { guards } from '@/guards'
import { router } from '.'
const inventoryManagementRoutes = [
    {
        path:"/inventory-metadata",
        name:"ItemMetadata",
        component:()=>import("../views/dashboard/inventoryManagement/InventoryMetadata"),
        beforeEnter: () =>  guards.isStockOfficer() || guards.isStockManager() || router.push({ name: 'Unauthorized' }),
    },
    {
        path:"/items",
        name:"Items",
        component:()=>import("../views/dashboard/inventoryManagement/ItemsManagement/Items"),
        beforeEnter: () =>  guards.isStockOfficer() || guards.isStockManager() || router.push({ name: 'Unauthorized' }),
    },
    {
        path:"/item/:id",
        name:"ItemDetails",
        component:()=>import("../views/dashboard/inventoryManagement/ItemsManagement/ItemDetails"), 
        beforeEnter: () =>  guards.isStockOfficer() || guards.isStockManager() || router.push({ name: 'Unauthorized' }),  
        props:true
    },
    {
       path:"/add-inventory-item/item/:id/", 
       name:"CreateInventoryItem",
       component:()=>import("../views/dashboard/inventoryManagement/CreateInventoryItem"),
       beforeEnter: () =>  guards.isStockOfficer() || guards.isStockManager() || router.push({ name: 'Unauthorized' }),
       props:true
    },
    {
        path:"/edit-inventory-item/item/:id/item-inventory/:invId", 
        name:"EditInventoryItem",
        component:()=>import("../views/dashboard/inventoryManagement/EditInventoryItem"),
        beforeEnter: () =>  guards.isStockOfficer() || guards.isStockManager() || router.push({ name: 'Unauthorized' }),
        props:true
    },
    {
        path:"/inventory-items/:id",
        name:"InventoryItemsDetails",
        component:()=>import("../views/dashboard/inventoryManagement/InventoryItemDetails"),
        beforeEnter: () =>  guards.isStockOfficer() || guards.isStockManager() || router.push({ name: 'Unauthorized' }),
        props:true
    },

    {
        path:"/item-inventories/",
        name:"ItemInventories",
        component:()=>import("../views/dashboard/inventoryManagement/ItemInventories"),
        beforeEnter: () =>  guards.isStockOfficer() || guards.isStockManager() || router.push({ name: 'Unauthorized' }),
        props:true
    
    }

    // STOCK_OFFICER, STOCK_MANAGER
 
 
     
   
    
]

export {inventoryManagementRoutes}

