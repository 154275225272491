
const homeRoutes = [
    {
        path: '/',
        name: 'Home',
        component: ()=>import('../views/auth/login')
    },
   
    {
        path: '/set-password',
        name: 'SetPassword',
        component: ()=>import('../views/auth/SetPassword')
    },
    {
        path: '/login-loading',
        name: 'LoginLoad',
        component: ()=>import('../views/auth/LoginLoad')
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ()=>import('../views/auth/ForgotPassword')
    },
   

  
]

export { homeRoutes }
