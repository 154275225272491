import { router } from '@/router'
import { apiUrl } from '@/sharevariables'
import { alert } from '@/utils/alerts'
import axios from 'axios'

const actions = {
    login: (context, payload) => {
       
       const url=`${apiUrl}/oauth/token`
       const str = `${payload.username}:${payload.password}`
       const enc = window.btoa(str)

       axios({
            method: 'post',
            url: url,
            headers: {
             'Content-Type': 'application/x-www-form-urlencoded',
             'Authorization': `Basic ${enc}`
            }
       }).then(res => {
            
            const token = res.data.token
            context.commit('mutateCredentials',token)
            context.commit('mutateIsAuthenticated',true)
            router.push({ name: 'LoginLoad'  })
        }).catch(err => {
            alert.error("Wrong Credentials")
            console.log(err);
        })
   
    },

    // get user info
    // get user permissions
    getUserInfo: (context,payload)=>{
        const url = `${apiUrl}/api/v1/users/get-user-info`
        axios.get(url,{
          headers: {
            'authorization': `Bearer ${payload}`
          }
        }).then(res=>{
            context.commit('mutateUserProfile',res.data);
            context.commit('mutateUserPermissions',res.data.roles.map(role=>role.name))
            if(res.data.defaultPassword){
                router.push({ name: 'SetPassword'  })
            }else{
                router.push({ name: 'Dashboard'  })
            }
    
        }).catch(err=>{
            console.log(err)
        })
     },

    // logout
    logout: (context) => {
        context.commit('mutateCredentials',null)
        context.commit('mutateIsAuthenticated',false)
        context.commit('mutateUserProfile',null)
        router.push({ name: 'Home'  })
    },

    setLoading: (context,payload)=>{
        context.commit('mutateLoading',payload)
    }
}
export { actions }
