import { router } from '.'
import { guards } from '@/guards'
const projectsManagementRoutes = [
   
  
    {
        path:'/create-project/customer/:customerId',
        name: 'CreateProject',
        component:()=>import('../views/dashboard/CustomerManagement/ProjectManagement/CreateProject.vue'),
        beforeEnter: () => guards.isAdministrator() || router.push({ name: 'Unauthorized' }),
        props: true
    },
    {
        path:'/project/:projectId/customer/:customerId',
        name: 'EditProject',
        component:()=>import('../views/dashboard/CustomerManagement/ProjectManagement/EditProject.vue'),
        beforeEnter: () => guards.isAdministrator() || router.push({ name: 'Unauthorized' }),
        props: true
    },
  
    {
        path:'/projects',
        name: 'Projects',
        component:()=>import('../views/dashboard/CustomerManagement/ProjectManagement/Projects.vue'),
        beforeEnter: () => guards.isAdministrator() || router.push({ name: 'Unauthorized' }),
    },

    {
        path:'/project-details/:id/customer/:customerId',
        name: 'ProjectDetails',
        component:()=>import('../views/dashboard/CustomerManagement/ProjectManagement/ProjectDetails.vue'),
        beforeEnter: () => guards.isAdministrator() || router.push({ name: 'Unauthorized' }),
        props: true
    }

    // ADMIN ROLE
  
    
]
export { projectsManagementRoutes  }
