import { router } from "../router/";
import eventBus from './eventBus';

const validate_status = (response, dashboard = false) => {
    if (response.status == 401)
        eventBus.emit('show-login-modal'); 
    else if (response.status == 404)
        router.push({ name: dashboard ? '404_dashboard' : '404' })
    else if (response.status == 403)
        router.push({ name: dashboard ? '403_dashboard' : '403' })
    else if (response.status == 500)
        router.push({ name: dashboard ? '500_dashboard' : '500' })
}

export const handler = (err, dashboard = false) => {
    if (err.response)
        validate_status(err.response, dashboard)
    else if (err.request) {
        router.push({ name: 'No Internet' })
    } else
        router.push({ name: dashboard ? '500_dashboard' : '500' })
}

