import moment from "moment";

const formatter = {
    formatNumber: function(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    

    formatDate: function(date) {
        let timestamp = date;
        if(timestamp < 1e12){
            timestamp = timestamp * 1000;        
        }
        let convertedDate = new Date(timestamp)

     return  moment(convertedDate).format('MMMM Do YYYY, HH:mm:ss a');
    }

    
       
}

export { formatter }