import Swal from "sweetalert2"

const alert = {
    success: (message) => {
        Swal.fire({
            icon: "success",
            title: "Success",
            text: "  "+message,
            showConfirmButton: false,
            timer: 5000
        })
    },

    promptDelete: (deleteFn,data='', msg='',title='') => {
        Swal.fire({
            title: title? title:"Are you sure ?",
            text: msg? msg:"You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
          
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-secondary mx-1",
                cancelButton: "btn btn-danger ml-1"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                deleteFn(data)
            }
        })
    },
    promptSure: (deleteFn, data = '') => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
           
            confirmButtonText: "Yes, am Sure!",
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-secondary mx-1",
                cancelButton: "btn btn-danger ml-1"
            }
        }).then(result => {
            if (result.value) {
                deleteFn(data);
            }
        });
    },

    promptWithContent: (placeholder, callFn, param) => {
        Swal.fire({
            input: 'textarea',
            inputPlaceholder: placeholder,
            showCancelButton: true
        }).then(result => {
            if (result.value) {
                callFn(result.value, param);
            }
        })
    },
    error: msg => {
        Swal.fire({
            icon: "error",
            position: "center",
            showConfirmButton: false,
            timer: 5000,
            html: " " + msg
        });
    },
    success_center: msg => {
        Swal.fire({
            icon: "success",
            position: "center",
            showConfirmButton: false,
            timer: 5000,
            text: "   " + msg
        });
    },
    info_center: msg => {
        Swal.fire({
            icon: "info",
            position: "center",
            showConfirmButton: false,
            // timer: 5000,
            text: "   " + msg
        });
    },
    info_center_html: msg => {
        Swal.fire({
            icon: "info",
            position: "center",
            showConfirmButton: false,
            // timer: 5000,
            html: "   " + msg
        });
    },
    loading: () => {
        Swal.fire({
            icon: "info",
            title: "Hold on a moment!",
            text: "We are processing your request!",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });
    },
}


export { alert }