<template>
  <div>
    <router-view />
    <InLoginModal />
  </div>
 
 
</template>

<script>


export default {
  name: 'App',
  components: {
  }
}
</script>

<style>

</style>
