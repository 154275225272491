import {createRouter, createWebHistory} from "vue-router";
import HomeLayout from "@/layouts/HomeLayout";
import DashboardLayout from "@/layouts/DashboardLayout";
import {homeRoutes} from "@/router/homeRoutes";
import {dashboardRoutes} from "@/router/dashboardRoutes";
import { guards } from "@/guards";
// import { routeHistory } from "./routerHistory";

const routes = [
  {
    path: '/',
    name: 'HomeLayout',
    component: HomeLayout,
    children: [
        ...homeRoutes
    ]
  },
    {
        path: '/dashboard',
        name: 'DashboardLayout',
        component: DashboardLayout,
        beforeEnter:()=>(guards.is_authenticated()),
        children: [
            ...dashboardRoutes
        ]
    },
    {
        path: '/404/',
        name: '404',
        component: () => import('../views/error_pages/404Status')
    },
    {
        path: '/403/',
        name: '403',
        component: () => import('../views/error_pages/403Status')
    },
    {
        path: '/500/',
        name: '500',
        component: () => import('../views/error_pages/500Status')
    },
    {
        path: '/nointernet/',
        name: 'No Internet',
        component: () => import('../views/error_pages/NoInternet')
    },
]

const router = createRouter({

    history: createWebHistory(),
    routes
})

// router.beforeEach((to, from, next) => {
//     routeHistory.previous = from.name;
//     routeHistory.current = to.name;
//     next();
//   });
  

export { router }
