import {createStore} from 'vuex'
import {mutations} from "@/store/mutations";
import {actions} from "@/store/actions";
import {getters} from "@/store/getters";
import { plugins } from './plugins';

const store = createStore({
    state:{
        auth:{
            credentials: null,
            userProfile: null,
            userPermissions: [],
            isAuthenticated: false
        },
        loading: false,
    },
    mutations:{
        ...mutations
    },
    actions:{
        ...actions
    },
    getters:{
        ...getters
    },
    
    plugins:[
        ...plugins
    ]



})
export {store}

