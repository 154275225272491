import { guards } from '@/guards'
import { router } from '.'
const customersManagementRoutes = [
    {
        path:'/customers',
        name: 'Customers',
        component:()=>import('../views/dashboard/CustomerManagement/Customers'),
        beforeEnter: () => guards.isAdministrator() || router.push({ name: 'Unauthorized' }),
    },
    {
        path:'/create-customer',
        name: 'CreateCustomer',
        component:()=>import('../views/dashboard/CustomerManagement/CreateCustomer'),
        beforeEnter: () => guards.isAdministrator() || router.push({ name: 'Unauthorized' }),
       
    },
    {
        path:'/edit-customer/:id',
        name: 'EditCustomer',
        component:()=>import('../views/dashboard/CustomerManagement/EditCustomer'),
        beforeEnter: () => guards.isAdministrator() || router.push({ name: 'Unauthorized' }),
        props: true
       
    },
    {
        path:'/customer-details/:id',
        name: 'CustomerDetails',
        component:()=>import('../views/dashboard/CustomerManagement/CustomerDetails'),
        beforeEnter: () => guards.isAdministrator() || router.push({ name: 'Unauthorized' }),
        props: true
    }

    // ADMIN ROLE
]
export { customersManagementRoutes }
