<template>
    <div>
        <slot name="trigger"></slot>
        <slot name="target"></slot>
    </div>
</template>

<script>
// import { nextTick } from 'vue';
import { Collapse } from 'bootstrap';

export default {
    name: 'BsCollapse',
    props: {
        toggle: Boolean,
        id: {
            type: String,
            required: true
        }
    },
    mounted() {
        console.log('mounted');
        this.$nextTick(() => { // Use this.$nextTick instead of nextTick directly
            console.log('nextTick');
            // Direct DOM manipulations here, but remember Vue doesn't recommend manipulating DOM directly like this
            const trigger = document.querySelector(`[data-bs-target="#${this.id}"]`); // Example selector, adjust as needed
            const target = document.getElementById(this.id);

            if (trigger && target) {
                console.log('collapse');
                target.classList.add('collapse');
                new Collapse(target, { toggle: this.toggle });
            }
        });
    }
}
</script>
